exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sections-01-header-tsx": () => import("./../../../src/pages/sections/01Header.tsx" /* webpackChunkName: "component---src-pages-sections-01-header-tsx" */),
  "component---src-pages-sections-02-about-tsx": () => import("./../../../src/pages/sections/02About.tsx" /* webpackChunkName: "component---src-pages-sections-02-about-tsx" */),
  "component---src-pages-sections-03-teaching-tsx": () => import("./../../../src/pages/sections/03Teaching.tsx" /* webpackChunkName: "component---src-pages-sections-03-teaching-tsx" */),
  "component---src-pages-sections-03-topskills-tsx": () => import("./../../../src/pages/sections/03Topskills.tsx" /* webpackChunkName: "component---src-pages-sections-03-topskills-tsx" */),
  "component---src-pages-sections-04-experience-tsx": () => import("./../../../src/pages/sections/04Experience.tsx" /* webpackChunkName: "component---src-pages-sections-04-experience-tsx" */),
  "component---src-pages-sections-05-faq-tsx": () => import("./../../../src/pages/sections/05Faq.tsx" /* webpackChunkName: "component---src-pages-sections-05-faq-tsx" */),
  "component---src-pages-sections-06-portfolio-tsx": () => import("./../../../src/pages/sections/06Portfolio.tsx" /* webpackChunkName: "component---src-pages-sections-06-portfolio-tsx" */),
  "component---src-pages-sections-07-contact-tsx": () => import("./../../../src/pages/sections/07Contact.tsx" /* webpackChunkName: "component---src-pages-sections-07-contact-tsx" */),
  "component---src-pages-sections-08-footer-tsx": () => import("./../../../src/pages/sections/08Footer.tsx" /* webpackChunkName: "component---src-pages-sections-08-footer-tsx" */),
  "component---src-pages-sections-09-policy-tsx": () => import("./../../../src/pages/sections/09Policy.tsx" /* webpackChunkName: "component---src-pages-sections-09-policy-tsx" */)
}

